import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export const isCaseAssignedToMeQuery = gql`
  query IsYoungCaseAssignedToMeQUery($young: ID!) {
    isYoungCaseAssignedToMe(id: $young)
  }
`

export default graphql(isCaseAssignedToMeQuery, {
  options: {
    fetchPolicy: 'network-only',
    pollInterval: 500,
  },
})
