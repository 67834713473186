import React from 'react'

import { Box, Heading } from 'design-system'
import { EditButton } from 'components/icons'

const ProfileSection = ({
  updateByPicture,
  updateByName,
  headerTitle,
  children,
  title,
  after,
  editToggle,
  editButton,
  ...otherProps
}) => (
  <Box width="100%" maxWidth={6} {...otherProps}>
    <Box display="flex" m={15}>
      <Heading
        color={editButton ? '#000' : 'title-gray'}
        fontWeight="normal"
        lineHeight="title"
        fontSize={3}
        flex={1}
        pl={2}
      >
        {headerTitle}
      </Heading>
      <Box ml="auto" mr={2} onClick={editToggle}>
        {editButton ? editButton : <EditButton />}
      </Box>
    </Box>

    {/* Content Block */}
    <Box
      bg="white"
      borderRadius={'5px'}
      display="flex"
      minHeight={3}
      mt={2}
      mb={5}
      p={3}
    >
      {children}
    </Box>
    {/* <Box display="flex" alignItems="center" justifyContent="center" p={3}>
      <Text color="title-gray" fontSize={1} lineHeight="normal" m={0}>
        {after}
      </Text>
      {updateByPicture && (
        <Fragment>
      <Box
      bg="light-gray"
      borderRadius="50%"
      overflow="hidden"
      height={25}
      width={25}
      mx={1}
      >
      <img width="100%" src={updateByPicture} alt="updated-by-avatar" />
      </Box>
      <Text color="title-gray" fontSize={1} lineHeight="normal" m={0}>
      {updateByName}{' '}
      </Text>
        </Fragment>
      )}
    </Box> */}
  </Box>
)

export default ProfileSection
