const breakpoints = [0, '52em', '64em', '80em']

// aliases
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

export const theme = {
  breakpoints,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 36, 48, 80, 96],
  radii: [0, 2, 4, 16, 9999, '100%'],
  fontWeights: [400, 500, 700],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  fonts: {
    serif: 'athelas, georgia, times, serif',
    title:
      '"Manrope",-apple-system, BlinkMacSystemFont, helvetica, ubuntu, roboto, sans-serif',
    sans:
      '"Manrope", -apple-system, BlinkMacSystemFont, helvetica, ubuntu, roboto, sans-serif',
  },
  widths: [16, 32, 64, 128, 256, 512],
  sizes: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  colors: {
    dimgray: '#5d5d5d',
    'near-white': '#F6F6F6',
    'dark-gray': '#2C2C2C',
    'medium-gray': '#828282',
    'light-gray': '#8e8e8e',
    'title-gray': '#b4b4b4',
    'title-blue': '#1f28cf',
    purple: '#dadbf5',
    'steel-blue': '#ddd',
    red: '#FA474F',
    blue: '#2a8bf2',
    background: '#e9e9e9',
    menu: '#0D0F11',
    sidebar: '#1A1D21',
    'sidebar-hover': '#31363E',
    black: '#111',
    'near-black': '#222',
    white: '#fff',
    'off-white': '#BABBBC',
    button: '#0D0F11',
    chat: '#2A8BF2',
    'chat-alt': '#E8E8E9',
    notification: '#FA474F',
    'tag-light': '#E8E8E9',
    interest: '#1A1D21',
    'interest-text': '#fff',
    /* tags */
    education: '#c0e5ff',
    'education-text': '#74c5ff',
    housing: '#fff5c0',
    'housing-text': '#ffbf74',
    money: '#ffc0c0',
    'money-text': '#ff7474',
    health: '#c0ffd2',
    'health-text': '#67eba4',
    jobs: '#ebc0ff',
    'jobs-text': '#d374ff',
    family: '#ffdac0',
    'family-text': '#ffae74',
    issues: '#ffc0de',
    'issues-text': '#ff74c7',
    other: '#f8f8f8',
    'other-text': '#1a1a1a',
  },
}
