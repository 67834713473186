import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { theme } from 'design-system'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    width: 150,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(t => ({
  root: {
    fontFamily: theme.fonts.sans,
    '&:focus': {
      color: 'white',
      backgroundColor: theme.colors['dark-gray'],
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: t.palette.common.white,
      },
    },
  },
}))(MenuItem)

const StyledButton = withStyles({
  root: {
    textTransform: 'initial',
  },
})(Button)

export default function CustomizedMenus({ categories, onItemSelect, title }) {
  const [anchorEl, setAnchorEl] = useState(null)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose(e) {
    onItemSelect(e.currentTarget.dataset.id)
    setAnchorEl(null)
  }

  return (
    <div>
      <StyledButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={handleClick}
        disableRipple
        size="large"
      >
        <Typography variant="inherit" style={{ fontFamily: theme.fonts.sans }}>
          {title}
        </Typography>
      </StyledButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {categories.map((item, index) => (
          <StyledMenuItem
            key={item.value}
            onClick={handleClose}
            data-id={item.value}
            disableRipple
          >
            <Typography variant="inherit">{item.name}</Typography>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}
