import React, { useEffect } from 'react'
import { Text } from 'design-system'
import { withRouter, Redirect } from 'react-router-dom'

import { callSidebarPlaceholder } from 'utils/placeholder-factory'
import { StyledNavLink, activeStyle } from './style'

import Avatar from 'components/avatar'

const CasesList = ({ data, onClick, isAllCases, match }) => {
  const { allCases, cases, loading } = data
  const {
    params: { id },
  } = match

  useEffect(() => {
    if (allCases.length !== 0) isAllCases()
  }, [allCases, isAllCases])

  if (id === ':id' && cases && cases.length !== 0) {
    return <Redirect to={`/young/${cases[0].young.id}`} />
  }

  return allCases.length === 0 && loading
    ? callSidebarPlaceholder()
    : allCases.map(item => {
        const { young } = item
        const avatarSrc = young.avatar ? young.avatar.url : young.avatarUrl
        return (
          <StyledNavLink
            key={`item-case-${young.id}`}
            to={`/young/${young.id}`}
            title={young.name}
            activeStyle={activeStyle}
            onClick={onClick}
          >
            <Avatar
              src={avatarSrc}
              name={young.name}
              size={40}
              mr={12}
              ml={15}
              fontSize={0}
            />
            <Text color="off-white" fontSize={2} lineHeight="22px" m={0}>
              {young.name}
            </Text>
          </StyledNavLink>
        )
      })
}

export default withRouter(CasesList)
