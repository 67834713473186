import React from 'react'
import { compose } from 'react-apollo'

import AuthRoute from 'auth-route'

import { Page, Nav } from 'components'
import UserSettings from './user-settings'

const Settings = ({ history }) => {
  return (
    <Page bg="white">
      <Nav />
      <UserSettings history={history} />
    </Page>
  )
}

export default compose(AuthRoute)(Settings)
