import React from 'react'
import { compose } from 'recompose'
import 'styled-components/macro'

import getCurrentUser from 'graphql/queries/user/getCurrentUser'

import { Box, Text } from 'design-system'

const UserAvatar = ({ data }) => {
  if (!data) return null
  if (data.loading) return null
  let avatarSrc
  if (data && data.me) {
    avatarSrc = data.me.avatarUrl
    if (data.me.avatar) avatarSrc = data.me.avatar.url
  }

  if (!avatarSrc) {
    const name = data.me.name
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={3}
        width={40}
        height={40}
        bg="near-black"
        overflow="hidden"
        borderRadius="50%"
        backgroundImage="linear-gradient(-120deg, #f74747 0%, #632A8B 100%);"
      >
        <Text color="white" fontSize={1} lineHeight="0">
          {name.charAt(0).toUpperCase()}
          {name
            .split(' ')[1]
            .charAt(0)
            .toUpperCase()}
        </Text>
      </Box>
    )
  }

  return (
    <Box
      mt={3}
      width={40}
      height={40}
      bg="near-black"
      borderRadius="50%"
      overflow="hidden"
      css={{
        backgroundImage: `url(${avatarSrc})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  )
}

export default compose(getCurrentUser)(UserAvatar)
