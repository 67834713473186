import React, { Fragment } from 'react'
import { Query, graphql } from 'react-apollo'
import 'styled-components/macro'

import { getCurrentUserQuery } from 'graphql/queries/user/getCurrentUser'
import { appVersion } from 'constants/index'

import { Box, Heading } from 'design-system'
import BorderButton from 'components/buttons/border-button'
import UpdateAvatar from './update-avatar'
import UpdatePassword from './update-password'

async function logout(client, history) {
  await client.resetStore()
  localStorage.clear()
  // eslint-disable-next-line
  setTimeout(() => history.push('/login'), 100)
}

function UserSettings({ data, client, history }) {
  if (!data.me) history.push('/login')

  return (
    <Query query={getCurrentUserQuery}>
      {({ data, client }) => (
        <Fragment>
          {data && data.me && (
            <Box p={4} flex={1}>
              <Heading mb={3}>Settings</Heading>
              <Heading pt={4} fontSize={3}>
                Update Avatar
              </Heading>
              <UpdateAvatar
                src={data.me.avatar ? data.me.avatar.url : data.me.avatarUrl}
              />
              <UpdatePassword
                onPasswordUpdate={() => logout(client, history)}
              />
              <Heading pb={3} fontSize={3}>
                Version {appVersion}
              </Heading>
              <BorderButton
                title="Sign Out"
                onClick={() => logout(client, history)}
                bg="white"
                css={{ borderColor: '#d4d4d4' }}
                color="dimgray"
              />
            </Box>
          )}
        </Fragment>
      )}
    </Query>
  )
}

export default graphql(getCurrentUserQuery)(UserSettings)
