import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

const BlackButton = ({ title, action, disabled, ...otherProps }) => (
  <Button ml={3} onClick={action} disabled={disabled}>
    {title}
  </Button>
)

export default BlackButton

const Button = styled.button(
  ({ disabled, theme }) => ({
    display: 'inline-block',
    backgroundColor: disabled ? theme.colors['off-white'] : theme.colors.button,
    fontWeight: theme.fontWeights[1],
    fontSize: theme.fontSizes[1],
    fontFamily: theme.fonts.sans,
    color: theme.colors.white,
    padding: '3px 15px',
    outline: 'none',
    borderRadius: 3,
    height: '45px',
    minWidth: 170,
    border: 0,
    transition: 'opacity 0.3s ease',
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? 'default' : 'pointer',
  }),
  space
)
