import React, { useState } from 'react'
import { compose } from 'react-apollo'

import AuthRoute from 'auth-route'

import { Page, Nav } from 'components'
import { Box } from 'design-system'

import ChatList from './list'
import ChatWindow from './window'

function Chat({ data }) {
  const [youngId, setYoungId] = useState(null)
  const { authStatus } = data
  const { user } = authStatus

  const handleItemSelect = id => setYoungId(id)

  return (
    <Page>
      <Nav />
      <Box flex={1}>
        <Box display="flex">
          <ChatList user={user} onItemSelect={handleItemSelect} />
          <Box display="flex" flex={3} flexDirection="column" minWidth="0">
            {youngId && <ChatWindow young={youngId} user={user} />}
          </Box>
        </Box>
      </Box>
    </Page>
  )
}

export default compose(AuthRoute)(Chat)
