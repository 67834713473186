import React, { useEffect, useState, useCallback } from 'react'
import { compose } from 'react-apollo'
import AuthRoute from 'auth-route'

import { Box } from 'design-system'
import Form from './form'

const CreateLog = ({ youngId, onSubmitLog }) => {
  const [loading, setLoading] = useState(false)

  const isCloseKey = event => event.keyCode === 27

  const handleDismiss = useCallback(
    e => {
      if (loading) return false
      return isCloseKey(e) ? onSubmitLog() : true
    },
    [loading, onSubmitLog]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleDismiss, false)
  }, [handleDismiss])

  const onFormUpload = loading => setLoading(loading)

  return (
    <Box
      justifyContent="center"
      position="absolute"
      pt="50px"
      display="flex"
      height="100%"
      width="100%"
      top="0"
      flex={1}
      overflow="auto"
    >
      <Box
        position="absolute"
        height="100%"
        width="100%"
        top="0"
        bg="background"
      />
      <Form
        youngId={youngId}
        onFormSubmit={onSubmitLog}
        onFormUpload={onFormUpload}
      />
    </Box>
  )
}

export default compose(AuthRoute)(CreateLog)
