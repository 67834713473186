import React, { useState } from 'react'
import { Box, theme, Text } from 'design-system'

import { formatDateBirthday, getYears, getAge } from 'utils/date'
import YoungInfoEditCard from 'components/edit-cards/young-info'
import ProfileSection from 'components/sections/profile'

import 'react-datepicker/dist/react-datepicker.css'

const InfoCard = ({
  updateByPicture,
  submitInfo,
  birthdate,
  updatedBy,
  onSubmit,
  address,
  status,
  years,
}) => {
  const [fosterPlacement, setFosterPlacement] = useState(address)
  const [birthday, setBirthDay] = useState(new Date(birthdate))
  const [newStatus, setStatus] = useState(status)
  const [isEditOpen, setEdit] = useState(false)

  const submitChanges = () => {
    const status = newStatus.replace(' ', '_').toUpperCase()
    const dob = new Date(birthday).toISOString()
    const address = fosterPlacement
    submitInfo(dob, address, status)
    setEdit(false)
  }
  const age = getAge(birthday)

  return !isEditOpen ? (
    <ProfileSection
      after={`Last Updated on 21/03/2019 13:11 by`}
      updateByName={<span>{updatedBy}</span>}
      updateByPicture={updateByPicture}
      headerTitle="Basic Information"
      editToggle={() => setEdit(true)}
      mx="auto"
      mt={3}
    >
      <Box display="flex" width="100%" flexDirection="column">
        <Box display="flex" width="100%" flexDirection="column" m={2}>
          <Text
            color="title-blue"
            fontSize={1}
            lineHeight="normal"
            letterSpacing={1}
            m={0}
          >
            Address
          </Text>
          <Text color="black" lineHeight="normal" fontSize="22px" margin={0}>
            {fosterPlacement}
          </Text>
        </Box>
        <Box display="flex" width="100%" m={2}>
          <Box display="flex" width="100%" flexDirection="column">
            <Text
              color="title-blue"
              fontSize={theme.fontSizes[1]}
              lineHeight="normal"
              letterSpacing={1}
              m={0}
            >
              Status
            </Text>
            <Text color="black" lineHeight="normal" fontSize="22px" margin={0}>
              {newStatus}
            </Text>
          </Box>
          <Box display="flex" width="100%" flexDirection="column">
            <Text
              color="title-blue"
              fontSize={theme.fontSizes[1]}
              lineHeight="normal"
              letterSpacing={1}
              m={0}
            >
              Birthday - {formatDateBirthday(new Date(birthday).toISOString())}
            </Text>
            <Text color="black" lineHeight="normal" fontSize="22px" margin={0}>
              {getYears(birthdate)} years old
            </Text>
          </Box>
        </Box>
      </Box>
    </ProfileSection>
  ) : (
    <YoungInfoEditCard
      setFosterPlacement={setFosterPlacement}
      fosterPlacement={fosterPlacement}
      submitChanges={submitChanges}
      setBirthDay={setBirthDay}
      setStatus={setStatus}
      birthdate={birthdate}
      onSubmit={onSubmit}
      birthday={birthday}
      status={newStatus}
      age={age}
    />
  )
}

export default InfoCard
