import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const loginMutation = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        role
      }
    }
  }
`

const loginMutationOptions = {
  props: ({ mutate }) => ({
    login: async ({ email, password }) => {
      return mutate({
        variables: {
          email,
          password,
        },

        update: (cache, { data }) => {
          const { login } = data
          const { user } = login
          cache.writeData({
            data: {
              authStatus: {
                __typename: 'authStatus',
                logged: true,
                user: user.id,
                role: user.role,
              },
            },
          })
        },
      })
    },
  }),
}

export default graphql(loginMutation, loginMutationOptions)
