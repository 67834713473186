import React from 'react'
import { compose } from 'react-apollo'
import { NavLink } from 'react-router-dom'

import authRoute from 'auth-route'

import { Box, Text } from 'design-system'
import Avatar from 'components/avatar'

const YoungHeader = ({
  youngName,
  src,
  children,
  subtitle,
  subtitleAction,
  youngId,
  from,
}) => (
  <Box
    justifyContent="space-between"
    alignItems="center"
    display="flex"
    flex={1}
    mb={70}
    p={2}
  >
    <Box display="flex" alignItems="center">
      <Avatar src={src} size={60} name={youngName} mx={1} fontSize={2} />
      <Box display="flex" alignItems="flex-start" flexDirection="column" mx={3}>
        <NavLink
          key={`item-case`}
          to={
            from === 'timeline' ? `/young/${youngId}/info` : `/young/${youngId}`
          }
          title={subtitle}
        >
          <Text as="span" fontSize={3} color="black" fontWeight="600" m="3px">
            {youngName}
          </Text>
        </NavLink>
      </Box>
    </Box>
    <Box display="flex">{children}</Box>
  </Box>
)

export default compose(authRoute)(YoungHeader)
