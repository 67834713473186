import React, { useState } from 'react'
import 'styled-components/macro'

import { Box } from 'design-system'
import ConversationList from './conversation-list'
import { useWindowSize } from 'hooks'

function ChatList({ onItemSelect, user }) {
  const windowSize = useWindowSize()
  const [selected, setSelected] = useState(null)

  const handleItemClick = id => {
    onItemSelect(id)
    setSelected(id)
  }

  return (
    <Box
      flex="0 0 25%"
      css={{
        height: `${windowSize.height}px`,
      }}
    >
      <Box
        position="relative"
        height="100%"
        overflow="hidden"
        bg="white"
        borderRight="solid 1px #ddd"
        zIndex={9999}
      >
        <Box
          mr={-30}
          pr={30}
          height="100%"
          position="relative"
          css={{
            overflowX: 'hidden',
            overflowY: 'scroll',
            overscrollBehavior: 'contain contain',
          }}
        >
          <ConversationList
            user={user}
            selected={selected}
            handleItemClick={handleItemClick}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ChatList
