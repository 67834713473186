import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import {
  getCasesQuery,
  getOtherCasesQuery,
} from 'graphql/queries/cases/getCases'

const assignCaseToMeQuery = gql`
  mutation AssignCaseToMe($young: ID!) {
    assignCaseToMe(young: $young) {
      young {
        id
        name
        avatarUrl
        avatar {
          url
        }
      }
    }
  }
`

const assignCaseToMeOptions = {
  props: ({ mutate }) => ({
    assignCaseToMe: async ({ young }) => {
      return mutate({
        variables: {
          young,
        },
        // Update cache
        refetchQueries: [
          { query: getCasesQuery },
          { query: getOtherCasesQuery },
        ],
      })
    },
  }),
}

export default graphql(assignCaseToMeQuery, assignCaseToMeOptions)
