import React from 'react'
import { Box, Text } from 'design-system'
import PensiveIcon from '../../../images/pensive-face.png'

import { postCategories } from 'enums'

const EmptyPosts = ({ match, category }) => {
  const hasCategory = category && category.length > 0
  return (
    <Box
      width="100%"
      height="70%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      py="10px"
    >
      <Box
        width="400px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        py="10px"
      >
        <img src={PensiveIcon} width="60px" alt="None logs ico" />
      </Box>
      <Box width="400px">
        <Text fontSize={4} lineHeight="30px" textAlign="center">
          {!hasCategory && (
            <span>
              It’s a little lonely here you haven’t created any logs yet.
            </span>
          )}
          {hasCategory && (
            <span>No logs found under {postCategories[category]}.</span>
          )}
        </Text>
      </Box>
    </Box>
  )
}

export default EmptyPosts
