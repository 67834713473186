import React from 'react'
import { compose } from 'react-apollo'

import AuthRoute from 'auth-route'

import { Page, Nav, SideBar } from 'components'

const Young = () => (
  <Page>
    <Nav />
    <SideBar />
  </Page>
)

export default compose(AuthRoute)(Young)
