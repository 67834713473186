import React, { useState } from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'
import 'styled-components/macro'

import { Box, Heading } from 'design-system'
import BlackButton from 'components/buttons/black-button'

import changePasswordMutation from 'graphql/mutations/user/changePassword'

function UpdatePassword({ changePassword, onPasswordUpdate }) {
  const [password, setPassword] = useState('')

  function handleFileChange(e) {
    const value = e.target.value.trim()
    setPassword(value)
  }

  async function updatePassword() {
    await changePassword({ password })
    onPasswordUpdate()
  }

  return (
    <Box display="flex" pb={4} flexDirection="column">
      <Heading pt={4} fontSize={3}>
        Change Password
      </Heading>
      <Box mt={3} display="flex" alignItems="center">
        <Input
          type="text"
          name="changePassword"
          placeholder="New Password"
          autoComplete="off"
          position="relative"
          value={password}
          onChange={handleFileChange}
        />
        <Box mr={3}>
          <BlackButton
            flex={1}
            ml={2}
            title="Update Password"
            action={updatePassword}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default compose(changePasswordMutation)(UpdatePassword)

const Input = styled.input(({ theme }) => ({
  fontSize: theme.fontSizes[1],
  padding: theme.sizes[0],
  placeholderColor: '#fff',
  fontFamily: 'Manrope',
  borderRadius: '3px',
  outline: 'none',
  height: '45px',
  width: '100%',
  maxWidth: theme.sizes[4],
  appearance: 'none',
  border: `solid 1px ${theme.colors['off-white']}`,
}))
