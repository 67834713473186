import React, { useEffect, useState } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import styled from 'styled-components'
import 'styled-components/macro'

import getUnreadNotifications from 'graphql/queries/notification/getUnreadNotifications'

import { theme, Box } from 'design-system'
import { PeopleIcon, ChatIcon, SettingsIcon } from 'components/icons'
import UserAvatar from './user-avatar'

const activeStyle = {
  opacity: 1,
  backgroundColor: theme.colors['dark-gray'],
  borderLeft: `solid 2px ${theme.colors['light-gray']}`,
  paddingRight: 2,
  path: {
    fill: 'red',
  },
}

const Nav = ({ match, data, ...otherProps }) => {
  const [id, setId] = useState('/young/:id')
  const [subscription, setSubscription] = useState(null)
  const { notifications, subscribeToNewNotifications } = data
  const hasNotifications = notifications.find(n => n.unread === true)

  function subscribe() {
    setSubscription(subscribeToNewNotifications)
  }

  function unsubscribe() {
    if (subscription) {
      subscription()
    }
  }

  useEffect(() => {
    subscribe()
    return () => {
      unsubscribe()
    }
  }, []) /* eslint-disable-line */

  useEffect(() => {
    if (match.params.id) {
      setId(`/young/${match.params.id}`)
    }
  }, [match.params.id])

  return (
    <Box
      width={64}
      height="100vh"
      bg="menu"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {/* Avatar */}
      <UserAvatar />

      {/* Menu */}
      <Box width="100%" mt={6} display="flex" flexDirection="column">
        <Box flex={1} height={50}>
          <StyledNavLink to={id} title="People" activeStyle={activeStyle}>
            <PeopleIcon
              fill={
                match.path.includes('young') || match.path === '/'
                  ? theme.colors.white
                  : theme.colors.dimgray
              }
            />
          </StyledNavLink>
        </Box>

        <Box position="relative" flex={1} height={50}>
          <StyledNavLink
            position="relative"
            to="/chat"
            title="Chat"
            activeStyle={activeStyle}
          >
            <ChatIcon
              fill={
                match.path.includes('chat')
                  ? theme.colors.white
                  : theme.colors.dimgray
              }
              css={{
                zIndex: 0,
              }}
            />
            {hasNotifications && (
              <Box
                position="absolute"
                width="10px"
                height="10px"
                bg="red"
                borderRadius="50%"
                css={{ zIndex: 2, top: 10, right: 16 }}
              />
            )}
          </StyledNavLink>
        </Box>

        <Box flex={1} height={50}>
          <StyledNavLink
            to="/settings"
            title="Settings"
            activeStyle={activeStyle}
          >
            <SettingsIcon
              fill={
                match.path.includes('settings')
                  ? theme.colors.white
                  : theme.colors.dimgray
              }
            />
          </StyledNavLink>
        </Box>
      </Box>
    </Box>
  )
}

export default compose(getUnreadNotifications)(withRouter(Nav))

const StyledNavLink = styled(NavLink)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
})
