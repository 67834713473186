import React from 'react'
import styled from 'styled-components'
import { color } from 'styled-system'

const BorderButton = ({ title, onClick, ...otherProps }) => (
  <Button bg={'background'} {...otherProps} onClick={onClick}>
    {title}
  </Button>
)

export default BorderButton

const Button = styled.button(
  ({ theme }) => ({
    fontWeight: theme.fontWeights[1],
    borderColor: theme.colors.black,
    fontSize: theme.fontSizes[1],
    fontFamily: theme.fonts.sans,
    color: theme.colors.black,
    padding: '3px 5px',
    borderRadius: 3,
    outline: 'none',
    height: '45px',
    width: 130,
  }),
  color
)
