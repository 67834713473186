import styled from 'styled-components'
import { theme } from 'design-system'

export const InputInfo = styled.input({
  borderRadius: theme.radii[2],
  height: theme.sizes[3],
  fontFamily: 'Manrope',
  maxHeight: '37px',
  fontSize: '22px',
  color: theme.colors['title-gray'],
  outline: 'none',
  resize: 'none',
  border: 0,
  '::placeholder': {
    fontFamily: 'inherit',
    color: theme.colors['title-gray'],
    fontSize: '22px',
    opacity: 1,
  },
})

export const SelectStatus = styled.select({
  fontFamily: 'Manrope',
  fontSize: '22px',
  color: theme.colors['title-gray'],
  outline: 'none',
  height: '35px',
  width: '70%',
  border: 0,
})

export const TextareaInput = styled.textarea({
  borderRadius: theme.radii[2],
  minHeight: theme.sizes[3],
  height: theme.sizes[3],
  fontFamily: 'Manrope',
  marginLeft: '6px',
  fontSize: '22px',
  marginTop: '7px',
  outline: 'none',
  resize: 'none',
  border: 0,
  '::placeholder': {
    color: theme.colors['title-gray'],
    fontFamily: 'inherit',
    fontSize: '22px',
    opacity: 1,
  },
})

export const InputInsertInterest = styled.input({
  borderRadius: theme.radii[2],
  fontFamily: 'Manrope',
  fontSize: '14px',
  outline: 'none',
  height: '22px',
  resize: 'none',
  width: '100px',
  color: '#000',
  border: 0,
})

export const ConfirmInterestBtn = styled.button({
  fontFamily: 'Manrope',
  background: 'transparent',
  marginLeft: '10px',
  cursor: 'pointer',
  color: '#0a8616',
  outline: 'none',
  border: 'none',
})

export const CloseInterestBtn = styled.button({
  background: 'transparent',
  fontFamily: 'Manrope',
  marginLeft: '10px',
  cursor: 'pointer',
  color: '#FF7474',
  outline: 'none',
  border: 'none',
})

export const CloseInsertInterestBtn = styled.button({
  background: 'transparent',
  fontFamily: 'Manrope',
  marginRight: '10px',
  cursor: 'pointer',
  color: '#FF7474',
  outline: 'none',
  border: 'none',
})

export const SelectInterest = styled.select({
  fontFamily: 'Manrope',
  marginRight: '10px',
  borderRadius: '4px',
  fontSize: '14px',
  outline: 'none',
  height: '30px',
  color: '#000',
  width: '70%',
  border: 0,
})
