import React, { useState, useEffect, createRef } from 'react'
import { compose } from 'recompose'
import 'styled-components/macro'
import Linkify from 'react-linkify'

import { Box, Text } from 'design-system'
import { AttachmentIcon } from 'components/icons'
import { useWindowSize } from 'hooks'
import SendMessageInput from './send-message-input'

import getYoungMessages from 'graphql/queries/young/getYoungMessages'
import sendMessage from 'graphql/mutations/chat/sendMessage'
import markNotificationsAsReadMutation from 'graphql/mutations/notification/markNotificationsAsRead'

const MessageInput = compose(sendMessage)(SendMessageInput)

function ChatMessages({ young, user, data, markNotificationsAsRead }) {
  const [subscription, setSubscription] = useState(null)
  const { messages, subscribeToNewMessages } = data
  const messagesRef = createRef()
  const windowSize = useWindowSize()
  const filteredMessages = messages.filter(
    m => m.sender === young || m.sender === user
  )

  function subscribe() {
    setSubscription(subscribeToNewMessages)
  }

  function unsubscribe() {
    if (subscription) {
      subscription()
    }
  }

  function scrollToBottom() {
    const scrollHeight = messagesRef.current.scrollHeight
    const height = messagesRef.current.clientHeight
    const maxScrollTop = scrollHeight - height
    messagesRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
  }

  // mark notifications as read
  useEffect(() => {
    markNotificationsAsRead({ young })
  }, [young, messages, markNotificationsAsRead])

  useEffect(() => {
    // run every render
    scrollToBottom()
  })

  // run on mount/unmount only once
  useEffect(() => {
    subscribe()
    return () => {
      unsubscribe()
    }
  }, []) /* eslint-disable-line */

  return (
    <Box display="flex" flex="1 1 0%" flexDirection="row-reverse">
      <Box
        position="relative"
        display="flex"
        flex="2 0 0%"
        overflow="hidden"
        flexDirection="column"
        zIndex={1}
      >
        <Box
          position="relative"
          css={{
            /* [window height] - [header] - [sendMessageInput] */
            height: `calc(${windowSize.height}px - 72px)`,
          }}
        >
          <Box position="relative" height="100%" p={3} overflow="hidden">
            <Box
              ref={messagesRef}
              position="relative"
              mr={-30}
              py={3}
              pr={30}
              height="100%"
              display="flex"
              flexDirection="column"
              css={{
                overscrollBehavior: 'contain contain',
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}
            >
              {filteredMessages.length > 0 && (
                <React.Fragment>
                  {filteredMessages.map(chat => {
                    const myself = chat.sender === user
                    const { file } = chat
                    const image = file && file.mimetype.includes('image')
                    return (
                      <Box key={`message-${chat.id}`}>
                        {file && image && (
                          <a href={file.url} download>
                            <Box
                              ml={myself ? 'auto' : '0'}
                              mb={3}
                              borderRadius={2}
                              width="100%"
                              maxWidth={5}
                              height="auto"
                              overflow="hidden"
                            >
                              <img
                                width="100%"
                                src={file.url}
                                alt={chat.message}
                                style={{ border: 0, outline: 'none' }}
                              />
                            </Box>
                          </a>
                        )}

                        {file && !image && (
                          <a
                            href={file.url}
                            rel="noreferrer noopener nofollow"
                            download
                            css={{
                              outline: 'none',
                            }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              maxWidth={4}
                              p={3}
                              mb={3}
                              ml={myself ? 'auto' : '0'}
                              bg={myself ? 'chat' : 'near-white'}
                              borderRadius={2}
                            >
                              <Box height={20} mt="4px" mr={2}>
                                <AttachmentIcon
                                  fill={myself ? 'white' : 'black'}
                                  width={20}
                                />
                              </Box>
                              <Text
                                fontSize={0}
                                color={myself ? 'white' : 'black'}
                                css={{
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                              >
                                {file.filename}
                              </Text>
                            </Box>
                          </a>
                        )}
                        <Box
                          ml={myself ? 'auto' : '0'}
                          mb={3}
                          p={3}
                          display="table"
                          maxWidth={5}
                          bg={myself ? 'chat' : 'near-white'}
                          borderRadius={2}
                        >
                          <Linkify>
                            <Text
                              m={0}
                              lineHeight="copy"
                              color={myself ? 'white' : 'black'}
                              css={{
                                wordWrap: 'break-word',
                                a: {
                                  color: myself ? 'blue' : 'blue',
                                },
                              }}
                            >
                              {chat.message}
                            </Text>
                          </Linkify>
                        </Box>
                      </Box>
                    )
                  })}
                </React.Fragment>
              )}
            </Box>
          </Box>
          <MessageInput young={young} user={user} />
        </Box>
      </Box>
    </Box>
  )
}

export default compose(
  getYoungMessages,
  markNotificationsAsReadMutation
)(ChatMessages)
