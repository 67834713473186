import React, { Fragment, useState } from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'
import { rem } from 'polished'

import getCurrentUser from 'graphql/queries/user/getCurrentUser'

import { Box, Heading, Text } from 'design-system'

import Tag from 'components/tag'
import Avatar from 'components/avatar'
import { AttachmentIcon } from 'components/icons'
import { postCategories } from 'enums'

const LogCard = ({
  data,
  text,
  onChange,
  onSelect,
  onFileSelect,
  ...otherProps
}) => {
  const [file, setFile] = useState(undefined)

  const handleFileChange = e => {
    const files = e.target.files
    const file = files[0]
    setFile(file)
    onFileSelect(file)
  }

  return (
    <Box width="100%" maxWidth={6} mx="auto" mt={6} {...otherProps}>
      <Box display="flex" m={15}>
        <Heading
          color="off-white"
          fontWeight="normal"
          lineHeight="title"
          fontSize={3}
          flex={1}
          pl={2}
        >
          Create Log
        </Heading>
      </Box>

      {/* Content Block */}
      <Box
        borderRadius={'10px'}
        display="flex"
        minHeight={3}
        mt={2}
        p={4}
        bg="white"
      >
        <Box display="flex" width="100%" flexDirection="column">
          {/* Text */}
          <Box display="flex" width="100%" flexDirection="column" mb="10px">
            <Textarea
              rows="4"
              cols="50"
              resize="none"
              name="text"
              placeholder="Start Typing..."
              onChange={e => onChange(e)}
            />
          </Box>

          {/* Sender */}
          <Box display="flex" width="100%" mt={2}>
            <Fragment>
              {data && data.me && (
                <Box display="flex" alignItems="center" minWidth="220px">
                  <Avatar
                    size={50}
                    mr={2}
                    src={data.me.avatarUrl}
                    name={data.me.name}
                  />
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                    mx={1}
                  >
                    <Text
                      as="span"
                      fontSize={2}
                      color="black"
                      lineHeight="22px"
                      fontWeight="600"
                      ml="3px"
                    >
                      {data.me.name}
                    </Text>
                    <Text
                      as="span"
                      color="off-white"
                      fontSize="13px"
                      lineHeight="18px"
                      ml="3px"
                    >
                      {data.me.role}
                    </Text>
                  </Box>
                </Box>
              )}
            </Fragment>

            <Box
              position="relative"
              display="flex"
              width="100%"
              justifyContent="flex-end"
              alignItems="center"
            >
              <UploadBox>
                <Tag bgColor="tag-health">
                  <AttachmentIcon width={20} height={20} stroke="#D9D9D9" />
                  <Text
                    lineHeight="19px"
                    fontSize={1}
                    ml="5px"
                    mr="5px"
                    color="black"
                  >
                    Add Attachment
                  </Text>
                </Tag>
                <form>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    onClick={e => {
                      e.target.value = null
                    }}
                  />
                </form>
              </UploadBox>
              <Tag bgColor="tag-light">
                <Select
                  onChange={onSelect}
                  // defaultValue="EDUCATION"
                  // defaultChecked="Education"
                >
                  <option value="">Select Tag</option>
                  {Object.keys(postCategories).map((k, index) => (
                    <option key={`option-${index}`} value={k}>
                      {postCategories[k]}
                    </option>
                  ))}
                </Select>
              </Tag>
            </Box>
          </Box>
          {file && (
            <Box ml="auto" pr={1}>
              <Text fontSize={1}>
                <Text fontSize={1} as="span" color="medium-gray">
                  Attachment:
                </Text>{' '}
                {file.name}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" p={3}>
        <Text color="black" fontSize={1} lineHeight="19px" m={0}>
          Press Esc to cancel & Enter to post
        </Text>
      </Box>
    </Box>
  )
}

export default compose(getCurrentUser)(LogCard)

const Textarea = styled.textarea(({ theme }) => ({
  fontFamily: 'Manrope',
  borderRadius: theme.radii[2],
  fontSize: theme.fontSizes[4],
  outline: 'none',
  resize: 'none',
  border: 0,
  height: theme.sizes[3],
  minHeight: theme.sizes[3],
  marginBottom: theme.space[4],
  '::placeholder': {
    color: 'inherit',
    opacity: 1,
    fontFamily: 'inherit',
    fontSize: theme.fontSizes[4],
  },
}))

const Select = styled.select(({ theme }) => ({
  appearance: 'none',
  display: 'inline-block',
  padding: `${rem(10)} ${rem(5)}`,
  borderRadius: rem(3),
  fontSize: rem(15),
  backgroundColor: theme.colors['tag-light'],
  border: 0,
  outline: 'none',
  fontFamily: theme.bodyFontFamily,
}))

const UploadBox = styled(Box)({
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  'input[type=file]': {
    cursor: 'pointer',
    fontSize: '100px',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
  },
})
