import React, { useState } from 'react'
import { compose } from 'react-apollo'

import AuthRoute from 'auth-route'
import { Box } from 'design-system'
import { Page, Nav, SideBar } from 'components'

import CreateLog from './create-log'
import Header from './header'
import Posts from './posts'

const YoungTimeline = ({ match }) => {
  const young = match.params.id
  const [logModal, setLogModal] = useState(false)
  const [category, setCategory] = useState('')
  const handleLogModal = () => setLogModal(prevState => !prevState)
  const dismissLogModal = () => setLogModal(prevState => false)
  const handleFilterSelect = value => setCategory(prevState => value)
  const handleClearFilter = () => setCategory(prevState => '')

  const handleChangeYoung = () => {
    dismissLogModal()
    handleClearFilter()
  }

  return (
    <Page>
      <Box display="flex" position="fixed" width={320}>
        <Nav />
        <SideBar onChangeYoung={handleChangeYoung} />
      </Box>
      <Box flex={1} p={3} ml={320}>
        <Header
          young={young}
          onCreateLogButtonClick={handleLogModal}
          onFilterSelect={handleFilterSelect}
        />
        <Posts
          young={young}
          category={category}
          onClearButtonClick={handleClearFilter}
        />
        {logModal && <CreateLog youngId={young} onSubmitLog={handleLogModal} />}
      </Box>
    </Page>
  )
}

export default compose(AuthRoute)(YoungTimeline)
