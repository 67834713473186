import React, { useState } from 'react'
import { Box, Text } from 'design-system'

import YoungDreamEditCard from 'components/edit-cards/young-dream'
import ProfileSection from 'components/sections/profile'

const DreamCard = ({ dreamText, updatedBy, updateByPicture, submitDream }) => {
  const [dream, setDream] = useState(dreamText)
  const [isEditOpen, setEdit] = useState(false)

  const submitChanges = () => {
    submitDream(dream)
    setEdit(false)
  }

  return !isEditOpen ? (
    <ProfileSection
      after={`Last Updated on 21/03/2019 13:11 by`}
      updateByName={<span>{updatedBy}</span>}
      updateByPicture={updateByPicture}
      right={<span>•••</span>}
      editToggle={() => setEdit(true)}
      headerTitle="Dream"
      mx="auto"
      mt={3}
    >
      <Box display="flex" width="100%" flexDirection="column">
        <Text color="black" lineHeight="normal" fontSize="22px" margin={2}>
          {dreamText}
        </Text>
      </Box>
    </ProfileSection>
  ) : (
    <YoungDreamEditCard
      dreamText={dreamText}
      setDream={setDream}
      dream={dream}
      submitChanges={submitChanges}
    />
  )
}

export default DreamCard
