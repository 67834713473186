import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import { subscribeToNewNotifications } from 'graphql/subscriptions'

export const getUnreadNotificationsQuery = gql`
  query unreadNotifications($user: ID) {
    unreadNotifications(user: $user) {
      id
      sender
      recipient
      unread
    }
  }
`

export const getUnreadNotificationsOptions = {
  props: ({
    data: {
      error,
      loading,
      networkStatus,
      subscribeToMore,
      refetch,
      unreadNotifications,
    },
  }) => ({
    data: {
      error,
      loading,
      networkStatus,
      refetch,
      notifications: unreadNotifications || [],
      subscribeToNewNotifications: () => {
        return subscribeToMore({
          document: subscribeToNewNotifications,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            const { notification } = subscriptionData.data
            const unreadNotifications = [
              ...prev.unreadNotifications,
              notification,
            ]
            return {
              unreadNotifications,
            }
          },
        })
      },
    },
  }),
  options: ({ young }) => {
    const opts = {
      variables: { user: young },
      fetchPolicy: 'network-only',
    }
    if (!young) opts.pollInterval = 500
    return opts
  },
}

export default graphql(
  getUnreadNotificationsQuery,
  getUnreadNotificationsOptions
)
