import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const newNotificationQuery = gql`
  mutation newNotification($sender: ID!, $recipient: ID!) {
    newNotification(sender: $sender, recipient: $recipient) {
      id
      unread
    }
  }
`

const newNotificationOptions = {
  props: ({ mutate }) => ({
    newNotification: async ({ sender, recipient }) => {
      return mutate({
        variables: {
          sender,
          recipient,
        },
      })
    },
  }),
}

export default graphql(newNotificationQuery, newNotificationOptions)
