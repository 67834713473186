import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export const getCurrentUserQuery = gql`
  query MeQuery {
    me {
      id
      name
      avatarUrl
      status
      role
      email
      avatar {
        url
      }
    }
  }
`

export default graphql(getCurrentUserQuery, {
  options: { fetchPolicy: 'cache-first' },
})
