import React from 'react'
import { compose, graphql, Query } from 'react-apollo'

import { Redirect } from 'react-router-dom'

import { getAuthQuery } from './graphql/queries/user/getUserAuth'

const AuthRoute = () => Component => {
  return class extends React.Component {
    render() {
      return (
        <Query query={getAuthQuery}>
          {({ data }) => {
            const token = localStorage.getItem('token')
            const authenticated =
              data.authStatus && data.authStatus.logged && token
            return authenticated ? (
              <Component {...this.props} />
            ) : (
              <Redirect to={`/login`} />
            )
          }}
        </Query>
      )
    }
  }
}

export default compose(
  graphql(getAuthQuery),
  AuthRoute()
)
