import gql from 'graphql-tag'

export const subscribeToNewMessages = gql`
  subscription {
    chat {
      id
      sender
      recipient
      message
    }
  }
`

export const subscribeToNewNotifications = gql`
  subscription {
    notification {
      id
      sender
      recipient
      unread
    }
  }
`
