import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import 'styled-components/macro'

import { Box, Heading, Text } from 'design-system'
import LastMessage from './last-message'
import Avatar from 'components/avatar'

import getUnreadNotifications from 'graphql/queries/notification/getUnreadNotifications'

function ChatItem({
  data,
  young,
  user,
  avatarSrc,
  name,
  selected,
  onItemClick,
}) {
  const [subscription, setSubscription] = useState(null)
  const { notifications, subscribeToNewNotifications } = data
  const allNotifications = notifications.filter(n => n.sender === young)

  function subscribe() {
    setSubscription(subscribeToNewNotifications)
  }

  function unsubscribe() {
    if (subscription) {
      subscription()
    }
  }

  useEffect(() => {
    subscribe()
    return () => {
      unsubscribe()
    }
  }, []) /* eslint-disable-line */

  async function handleOnClick() {
    onItemClick(young)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      px={4}
      py={3}
      width="100%"
      minWidth={312}
      height={100}
      borderBottom="solid 1px #d3d3d3"
      bg={selected ? 'chat' : 'white'}
      css={{
        userSelect: 'none',
        transition: '0.3s ease',
      }}
      onClick={handleOnClick}
    >
      <Box width={50} height={50} borderRadius={4} flex="0 0 50px">
        <Avatar src={avatarSrc} name={name} size={50} />
      </Box>
      <Box ml={3} flex={1}>
        <Heading m={0} fontSize={3} color={selected ? 'white' : 'menu'}>
          {name}
        </Heading>
        <LastMessage young={young} user={user} selected={selected} />
      </Box>
      {allNotifications.length > 0 && (
        <Box
          ml="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={26}
          height={26}
          bg="red"
          borderRadius={5}
        >
          <Text fontSize={0} color="white" lineHeight={0}>
            {allNotifications.length}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default compose(getUnreadNotifications)(ChatItem)
