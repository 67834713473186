import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import 'styled-components/macro'

import { Box } from 'design-system'
import BlackButton from 'components/buttons/black-button'

import updateAvatarMutation from 'graphql/mutations/user/updateAvatar'

const useStyles = makeStyles(() => ({
  progress: {
    position: 'absolute',
    top: 17,
    left: 17,
    zIndex: 2,
  },
}))

function UpdateAvatar({ src, updateAvatar }) {
  const [avatar, setAvatarSrc] = useState(null)
  const [loading, setLoading] = useState(false)
  const classNames = useStyles()

  useEffect(() => {
    setAvatarSrc(src)
  }, [src])

  function setAvatarPreview(file) {
    const reader = new FileReader()
    reader.onload = e => {
      setAvatarSrc(e.target.result)
    }
    reader.readAsDataURL(file)
  }

  async function handleFileChange(e) {
    const files = e.target.files
    const file = files[0]
    setAvatarPreview(file)
    setLoading(true)
    await updateAvatar({ avatar: file })
    setLoading(false)
  }

  return (
    <Box display="flex" pt={4} alignItems="center">
      <Box width={75} height={75} position="relative">
        <Box
          position="absolute"
          width={75}
          height={75}
          borderRadius={5}
          overflow="hidden"
          css={{
            backgroundImage: `url(${avatar})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: 1,
            transition: '0.4s ease',
            opacity: loading ? 0.5 : 1,
          }}
        />
        {loading && <CircularProgress className={classNames.progress} />}
      </Box>

      <UploadBox>
        <BlackButton ml={2} title="Choose file..." />
        <form>
          <input
            type="file"
            onChange={handleFileChange}
            onClick={e => {
              e.target.value = null
            }}
          />
        </form>
      </UploadBox>
    </Box>
  )
}

export default compose(updateAvatarMutation)(UpdateAvatar)

const UploadBox = styled(Box)({
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  'input[type=file]': {
    cursor: 'pointer',
    fontSize: '100px',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
  },
})
