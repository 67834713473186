import React from 'react'
import { NavLink } from 'react-router-dom'

import YoungHeader from 'components/headers/young-header'
import DarkButton from 'components/buttons/dark-button'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import { callHeaderPlaceholder } from 'utils/placeholder-factory'

const StyledButton = withStyles({
  root: {
    textTransform: 'capitalize',
  },
})(Button)

const YoungProfileHeader = ({
  data,
  young,
  name,
  avatarSrc,
  assignCaseToMe,
  removeCase,
}) => {
  const { loading } = data
  const alreadyAdded = data && data.isYoungCaseAssignedToMe

  if (loading) return callHeaderPlaceholder()
  return (
    <YoungHeader
      youngName={name}
      src={avatarSrc}
      youngId={young}
      subtitle="View Timeline"
      from="profile"
    >
      <NavLink
        key={`item-case`}
        to={`/young/${young}`}
        title={`Back to ${name} timeline`}
      >
        <StyledButton variant="outlined" size="large" disableRipple>
          Back
        </StyledButton>
      </NavLink>
      {!alreadyAdded && (
        <DarkButton
          variant="contained"
          text="Add Case"
          onClick={() => assignCaseToMe({ young })}
        />
      )}
      {alreadyAdded && (
        <DarkButton
          variant="contained"
          text="Remove Case"
          onClick={() => removeCase({ young })}
        />
      )}
    </YoungHeader>
  )
}

export default YoungProfileHeader
