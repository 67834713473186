import React, { useState } from 'react'
import { Box, Text } from 'design-system'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { getCases, getOtherCases } from 'graphql/queries/cases/getCases'
// import { SearchIcon } from 'components/icons'

import CasesList from './cases-list'
import { callSmTitlePlaceholder } from 'utils/placeholder-factory'

const YourCases = compose(getCases)(CasesList)
const OtherCases = compose(getOtherCases)(CasesList)

const SideBar = ({ match, onChangeYoung }) => {
  const [allCases, setIsAllCases] = useState(true)
  const handleSetIsAllCases = () => setIsAllCases(prevState => false)

  return (
    <Box
      width="4"
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      bg="sidebar"
    >
      {/* Input search */}
      {/* <Box
        height={45}
        display="flex"
        alignItems="center"
        width="95%"
        position="relative"
        >
        {allCases ? (
          callSearchPlaceholder()
        ) : (
          <Fragment>
        <Box
        height="45px"
        width="45px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        border={0}
        position="absolute"
        >
        <SearchIcon width={17} height={17} fill="#D3D3D3" />
        </Box>
        <Input
        type="text"
        name="cases"
        placeholder="Search"
        autoComplete="off"
        position="relative"
        />
          </Fragment>
        )}
      </Box> */}

      {/* Your cases */}
      <Box
        height={30}
        display="flex"
        alignItems="center"
        width="100%"
        position="relative"
        mb={3}
        mt={3}
      >
        {allCases ? (
          callSmTitlePlaceholder()
        ) : (
          <Text color="white" fontSize={4} pl={3} m={0}>
            Your cases
          </Text>
        )}
      </Box>

      {/* Your cases */}
      <YourCases onClick={onChangeYoung} isAllCases={handleSetIsAllCases} />

      {/* Other cases */}
      <Box
        height={30}
        display="flex"
        alignItems="center"
        width="100%"
        position="relative"
        mb={3}
        mt={3}
      >
        {allCases ? (
          callSmTitlePlaceholder()
        ) : (
          <Text color="white" fontSize={4} pl={3} m={0}>
            Other
          </Text>
        )}
      </Box>
      <OtherCases onClick={onChangeYoung} isAllCases={handleSetIsAllCases} />
    </Box>
  )
}

export default withRouter(SideBar)

// const Input = styled.input(({ theme }) => ({
//   fontSize: theme.fontSizes[1],
//   padding: '0 10px 0 40px',
//   placeholderColor: '#fff',
//   fontFamily: 'inherit',
//   borderRadius: '3px',
//   outline: 'none',
//   height: '100%',
//   width: '100%',
//   border: 0,
// }))
