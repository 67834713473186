import React from 'react'
import { Box, Text } from 'design-system'
import BlackButton from 'components/buttons/black-button'

const NoConnection = ({ onDismiss }) => (
  <Box
    width="100vw"
    minHeight="100vh"
    display="flex"
    height="100vh"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <Box
      height="60px"
      width="60px"
      style={{
        backgroundImage:
          'url(https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/198/frowning-face-with-open-mouth_1f626.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'none',
      }}
    />
    <Box
      width="435px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      my="20px"
    >
      <Text textAlign="center" as="span" fontSize={4} lineHeight="30px">
        Oops the internet has disconnected some features might not work.
      </Text>
    </Box>
    <Box
      width="435px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <BlackButton title="Get back to work" action={() => onDismiss()} />
    </Box>
  </Box>
)

export default NoConnection
