import React from 'react'
import { Box } from 'design-system'

import { TextareaInput } from 'components/edit-cards/style'
import ProfileSection from 'components/sections/profile'
import { SubmitChange } from 'components/icons'

const YoungDreamEditCard = ({ dreamText, setDream, dream, submitChanges }) => (
  <ProfileSection
    after={`Last Updated on 21/03/2019 13:11 by`}
    updateByName={<span />}
    right={<span>•••</span>}
    editToggle={submitChanges}
    editButton={<SubmitChange />}
    headerTitle="Edit Mode"
    mx="auto"
    mt={3}
  >
    <Box display="flex" width="100%" flexDirection="column">
      <TextareaInput
        autoFocus
        rows="4"
        cols="50"
        resize="none"
        name="setDream"
        placeholder={dream}
        value={dream}
        onChange={e => setDream(e.target.value)}
      />
    </Box>
  </ProfileSection>
)

export default YoungDreamEditCard
