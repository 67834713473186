import React, { Fragment, useState } from 'react'
import 'styled-components/macro'
import { compose } from 'recompose'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import Tag from 'components/tag'
import { Box, Heading, Text } from 'design-system'
import { FileIcon, DownloadIcon } from 'components/icons'
import { DropdownMenu } from 'components/menu'
import { formatDate } from 'utils/date'

import EditableTextarea from 'components/edit-cards/timeline-textarea'
import Avatar from 'components/avatar'
import deletePostMutation from 'graphql/mutations/young/deletePost'
import updatePostMutation from 'graphql/mutations/young/updatePost'

import { postCategories } from '../../enums'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0),
  },
  input: {
    display: 'none',
  },
}))

const TimelineCard = ({
  postId,
  young,
  headerTitle,
  avatar,
  avatarUrl,
  file,
  senderName,
  senderRole,
  text,
  fileName,
  tagName,
  tagFileColor,
  tagFileTextColor,
  tagColor,
  tagTextColor,
  deletePost,
  editPost,
  updatePost,
  ...otherProps
}) => {
  const [edit, setEdit] = useState(false)
  const [textarea, setTextarea] = useState(text)
  const styles = useStyles()
  const avatarSrc = avatar ? avatar.url : avatarUrl

  const onDeleteMenuSelect = async () => {
    if (deletePost) {
      setEdit(false)
      await deletePost({ post: postId, young })
    }
  }

  const onEditMenuSelect = async () => {
    setEdit(true)
  }

  const onBackdropMenuSelect = () => setEdit(false)

  const onSaveButtonClick = () => {
    setEdit(false)
    updatePost({ post: postId, text: textarea })
  }

  const onCancelButtonClick = () => {
    setTextarea(text)
    setEdit(false)
  }

  const onTextareaChange = value => setTextarea(value)

  const StaticCopy = () => (
    <Box display="flex" width="100%" flexDirection="column" my="10px">
      <Text color="black" lineHeight="normal" fontSize="22px" margin={0}>
        {textarea}
      </Text>
    </Box>
  )

  return (
    <Box width="100%" maxWidth={6} mx="auto" mt={60} {...otherProps}>
      <Box display="flex" m={15} alignItems="center" minHeight={48}>
        <Heading
          color="title-gray"
          fontWeight="normal"
          lineHeight="title"
          fontSize={3}
          flex={1}
          pl={2}
        >
          {formatDate(headerTitle)}
        </Heading>
        <Box ml="auto" mr={2}>
          {!edit && (
            <DropdownMenu
              onDeleteMenuSelect={onDeleteMenuSelect}
              onEditMenuSelect={onEditMenuSelect}
              onBackdropMenuSelect={onBackdropMenuSelect}
            />
          )}
          {edit && (
            <Fragment>
              <Button
                color="primary"
                className={styles.button}
                onClick={onSaveButtonClick}
              >
                Save
              </Button>
              <Button
                color="secondary"
                className={styles.button}
                onClick={onCancelButtonClick}
              >
                Cancel
              </Button>
            </Fragment>
          )}
        </Box>
      </Box>

      {/* Content Block */}
      <Box
        bg="white"
        borderRadius={'5px'}
        display="flex"
        minHeight={3}
        mt={2}
        p={3}
        px="30px"
      >
        <Box display="flex" width="100%" flexDirection="column">
          {/* Text */}
          {!edit && <StaticCopy />}
          {edit && (
            <EditableTextarea
              initialText={text}
              onTextareaChange={onTextareaChange}
            />
          )}

          {/* Sender */}
          <Box display="flex" width="100%" my={2}>
            <Box display="flex" alignItems="center" minWidth="220px">
              {/* Image */}
              <Avatar mr={2} size={50} src={avatarSrc} name={senderName} />
              {/* Name and role */}
              <Box
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                mx={1}
              >
                <Text
                  as="span"
                  fontSize={2}
                  color="black"
                  lineHeight="22px"
                  fontWeight="600"
                  ml="3px"
                >
                  {senderName}
                </Text>
                <Text
                  as="span"
                  color="title-gray"
                  fontSize="13px"
                  lineHeight="18px"
                  ml="3px"
                >
                  {senderRole}
                </Text>
              </Box>
            </Box>

            <Box
              display="flex"
              width="100%"
              justifyContent="flex-end"
              alignItems="center"
            >
              {file && (
                <a href={file.url} rel="noreferrer noopener nofollow" download>
                  <Tag bgColor="background">
                    <FileIcon width={20} height={20} stroke="#b4b4b4" />
                    <Text
                      lineHeight="normal"
                      fontSize={1}
                      ml="10px"
                      mt="10px"
                      color="title-gray"
                      css={{
                        width: 100,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {file.filename}
                    </Text>
                    <DownloadIcon width={20} height={20} stroke="#b4b4b4" />
                  </Tag>
                </a>
              )}

              <Tag bgColor={tagColor}>
                <Text
                  lineHeight="normal"
                  fontSize={1}
                  margin={0}
                  color={tagTextColor}
                >
                  {postCategories[tagName]}
                </Text>
              </Tag>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default compose(
  deletePostMutation,
  updatePostMutation
)(TimelineCard)
