import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const options = ['Edit', 'Delete']

export default function DropdownMenu({
  onDeleteMenuSelect,
  onEditMenuSelect,
  onBackdropMenuSelect,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose(event, option) {
    if (option === 'Delete') onDeleteMenuSelect()
    else if (option === 'Edit') onEditMenuSelect()
    else onBackdropMenuSelect()
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            width: 200,
            boxShadow: `
              1px 5px 5px -3px rgba(0,0,0,0.01),
              0px 8px 10px 1px rgba(0,0,0,0.01),
              0px 3px 14px 2px rgba(0,0,0,0.01)
            `,
          },
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option}
            selected={option === 'Pyxis'}
            onClick={event => handleClose(event, option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
