import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import 'styled-components/macro'

import { Box, Text } from 'design-system'
import { ArrowLeftIcon, AttachmentIcon } from 'components/icons'

import newNotificationMutation from 'graphql/mutations/notification/newNotification'

function SendMessageInput({ sendMessage, user, young, newNotification }) {
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [attachment, setAttachment] = useState(null)

  useEffect(() => {
    // mount
    setAttachment(null)
    setValue('')
  }, [young])

  function handleOnChange(e) {
    setValue(e.target.value)
  }

  async function handleInputSubmit() {
    const inputValue = value.trim()
    if (inputValue.length === 0) return
    setLoading(true)
    await sendMessage({
      sender: user,
      recipient: young,
      message: inputValue,
      file: attachment,
    })
    newNotification({
      sender: user,
      recipient: young,
    })
    setLoading(false)
    setValue('')
    setAttachment(null)
  }

  function handleKeyPress(e) {
    if (loading) return
    if (e.keyCode === 13) {
      handleInputSubmit()
    }
  }

  function handleFileChange(e) {
    const files = e.target.files
    const file = files[0]
    setAttachment(file)
  }

  return (
    <Box
      px={3}
      display="flex"
      flex={3}
      height={76}
      bg="white"
      alignItems="center"
    >
      <UploadBox
        width={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={19} height={22} overflow="hidden" mt="4px" mr="8px">
          <AttachmentIcon width={19} height={22} fill="rgba(0, 0, 0, 0.3)" />
        </Box>
        <form>
          <input
            type="file"
            disabled={loading}
            onChange={handleFileChange}
            onClick={e => {
              e.target.value = null
            }}
          />
        </form>
      </UploadBox>
      <Input
        type="text"
        name="message"
        placeholder="Type a message..."
        value={value}
        autoComplete="off"
        autoCorrect="off"
        onChange={handleOnChange}
        onKeyDown={handleKeyPress}
        disabled={loading}
        autoFocus
      />
      {attachment && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          maxWidth={4}
          bg="near-white"
          borderRadius={100}
          px={3}
          mr={3}
        >
          <Box height={20} mt="4px" mr={2}>
            <AttachmentIcon fill="#5d5d5d" width={20} />
          </Box>
          <Text
            fontSize={0}
            css={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {attachment.name}
          </Text>
        </Box>
      )}
      <Box width={44} height={44}>
        {loading && <CircularProgress />}
        {!loading && (
          <Box
            flex="0 0 44px"
            ml="auto"
            width={44}
            height={44}
            borderRadius={4}
            bg="chat"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={handleInputSubmit}
          >
            <ArrowLeftIcon />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default compose(newNotificationMutation)(SendMessageInput)

const Input = styled.input(({ theme }) => ({
  width: '100%',
  appearance: 'none',
  border: 'none',
  background: 'none',
  outline: 0,
  flex: 1,
  fontFamily: 'inherit',
  fontSize: theme.fontSizes[3],
  fontWeight: theme.fontWeights[1],
}))

const UploadBox = styled(Box)({
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  'input[type=file]': {
    cursor: 'pointer',
    fontSize: '100px',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
  },
})
