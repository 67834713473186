import React, { Fragment } from 'react'
import { compose } from 'recompose'

import DarkButton from 'components/buttons/dark-button'
import YoungHeader from 'components/headers/young-header'
import Dropdown from 'components/dropdown'

import getYoung from 'graphql/queries/young/getYoung'

import { callHeaderPlaceholder } from 'utils/placeholder-factory'
import { postCategories } from 'enums'

const categories = Object.keys(postCategories).map(k => ({
  value: k,
  name: postCategories[k],
}))

const YoungPosts = ({
  onCreateLogButtonClick,
  onFilterSelect,
  young,
  data,
}) => {
  return data && !data.young ? (
    callHeaderPlaceholder()
  ) : (
    <Fragment>
      {data && data.young && (
        <Fragment>
          <YoungHeader
            youngName={data.young.name}
            src={
              data.young.avatar ? data.young.avatar.url : data.young.avatarUrl
            }
            subtitle="View Profile"
            youngId={young}
            from="timeline"
          >
            <Dropdown
              categories={categories}
              onItemSelect={onFilterSelect}
              title="Filter Logs"
            />
            <DarkButton text="Create Log" onClick={onCreateLogButtonClick} />
          </YoungHeader>
        </Fragment>
      )}
    </Fragment>
  )
}

export default compose(getYoung)(YoungPosts)
