import React, { useState } from 'react'
import { Box, Text } from 'design-system'

import TagAddButton from 'components/buttons/tag-button-add'
import ProfileSection from 'components/sections/profile'
import { SubmitChange } from 'components/icons'
import { youngInterests } from 'enums'
import Tag from 'components/tag'
import {
  CloseInsertInterestBtn,
  CloseInterestBtn,
  SelectInterest,
} from 'components/edit-cards/style'

const YoungInterestEditCard = ({
  submitChanges,
  isEditOpen,
  interests,
  onRemove,
  setEdit,
  onAdd,
}) => {
  const [isAddOpen, setAddOpen] = useState(false)

  return (
    <ProfileSection
      after={`Last Updated on 21/03/2019 13:11 by`}
      updateByName={<span>Loren Spears</span>}
      headerTitle="Edit Mode"
      editToggle={submitChanges}
      editButton={<SubmitChange />}
      mx="auto"
      mt={3}
    >
      <Box display="flex" flexWrap="wrap" alignItems="center">
        {interests.map(item => (
          <Tag bgColor="interest" key={`INTEREST_${item}`}>
            <Text
              lineHeight="normal"
              fontSize={1}
              margin={0}
              color="interest-text"
            >
              {youngInterests[item]}
            </Text>
            {isEditOpen && (
              <CloseInterestBtn onClick={() => onRemove(item)}>
                X
              </CloseInterestBtn>
            )}
          </Tag>
        ))}
        {!isAddOpen ? (
          <TagAddButton title="Add Tag   +" action={() => setAddOpen(true)} />
        ) : (
          <Tag px="0px" bgColor="#A6A7A8">
            <CloseInsertInterestBtn onClick={() => setAddOpen(false)}>
              X
            </CloseInsertInterestBtn>
            <SelectInterest
              onChange={e => {
                setAddOpen(prevState => !prevState)
                onAdd(e.target.value)
              }}
            >
              {Object.keys(youngInterests).map((item, index) => (
                <option
                  color="blue"
                  key={`option-${index}`}
                  value={item}
                  fontSize="14px"
                >
                  {youngInterests[item]}
                </option>
              ))}
            </SelectInterest>
          </Tag>
        )}
      </Box>
    </ProfileSection>
  )
}

export default YoungInterestEditCard
