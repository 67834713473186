import React, { Component } from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'

import LogCard from 'components/cards/log'
import Loading from 'components/loading/spinner'
import { Box } from 'design-system'

import createYoungPost from 'graphql/mutations/young/createYoungPost'

class LogForm extends Component {
  static defaultProps = {
    onFormSubmit: () => {},
  }

  state = {
    text: '',
    category: 'OTHER',
    file: null,
    loading: false,
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleFileSelect = file => this.setState({ file })

  handleKeyPress = e => {
    const { loading } = this.state
    if (loading) return
    if (e.key === 'Enter') {
      this.submit()
    }
  }

  handleSelectChange = e => {
    const { loading } = this.state
    if (loading) return
    this.setState({ category: e.target.value })
  }

  setLoading = loading => {
    const { onFormUpload } = this.props
    this.setState({ loading })
    onFormUpload(loading)
  }

  submit = async () => {
    const { youngId, createPost, onFormSubmit } = this.props
    const { text, category, file } = this.state
    this.setLoading(true)
    await createPost({ young: youngId, category, text, file })
    this.setLoading(false)
    onFormSubmit()
  }

  render() {
    const { loading } = this.state
    return (
      <Box
        justifyContent="center"
        position="absolute"
        pt={4}
        display="flex"
        height="100%"
        width="100%"
        top="0"
        flex={1}
        overflow="auto"
      >
        <Box
          position="absolute"
          height="100%"
          width="100%"
          top="0"
          bg="background"
        />
        {loading && (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}
        <Box width="100%" maxWidth="1100px" mx="auto" zIndex="2">
          <Box width="70%" bg="transparent">
            <LogCard
              onChange={this.handleChange}
              onFileSelect={this.handleFileSelect}
              onKeyPress={this.handleKeyPress}
              onSelect={this.handleSelectChange}
            />
          </Box>
        </Box>
      </Box>
    )
  }
}

export default compose(createYoungPost)(LogForm)

const LoadingWrapper = styled.div({
  top: 0,
  left: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '70%',
  height: '100%',
  zIndex: 9999,
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
})
