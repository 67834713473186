import React from 'react'
import { Box, Text } from 'design-system'
import DatePicker from 'react-datepicker'

import { InputInfo, SelectStatus } from 'components/edit-cards/style'
import ProfileSection from 'components/sections/profile'
import { SubmitChange } from 'components/icons'
import { getYears } from 'utils/date'
import { youngStatuses } from 'enums'

import 'react-datepicker/dist/react-datepicker.css'
import './style.css'

const YoungInfoEditCard = ({
  setFosterPlacement,
  fosterPlacement,
  submitChanges,
  setBirthDay,
  setStatus,
  birthdate,
  birthday,
  status,
  age,
}) => (
  <ProfileSection
    after={`Last Updated on 21/03/2019 13:11 by`}
    updateByName={<span />}
    headerTitle="Edit Mode"
    editToggle={submitChanges}
    editButton={<SubmitChange />}
    mx="auto"
    mt={3}
  >
    <Box display="flex" width="100%" flexDirection="column">
      <Box display="flex" width="100%" flexDirection="column" m={2}>
        <Text
          color="title-blue"
          fontSize="14px"
          lineHeight="normal"
          letterSpacing={1}
          m={0}
        >
          Address
        </Text>
        <InputInfo
          autoFocus
          value={fosterPlacement}
          onChange={e => setFosterPlacement(e.target.value)}
          name="fosterPlacement"
        />
      </Box>
      <Box display="flex" width="100%" m={2}>
        <Box display="flex" width="100%" flexDirection="column">
          <Text
            color="title-blue"
            fontSize="14px"
            lineHeight="normal"
            letterSpacing={1}
            m={0}
          >
            Status
          </Text>
          <SelectStatus
            onChange={e => {
              setStatus(youngStatuses[e.target.value])
            }}
          >
            <option value="">{status}</option>
            {Object.keys(youngStatuses).map((t, index) => (
              <option color="blue" key={`option-${index}`} value={t}>
                {youngStatuses[t]}
              </option>
            ))}
          </SelectStatus>
        </Box>
        <Box display="flex" width="100%" flexDirection="column">
          <Box display="flex">
            <Text
              color="title-blue"
              fontSize="14px"
              lineHeight="normal"
              letterSpacing={1}
              m={0}
            >
              Birthday -
            </Text>
            <DatePicker
              className="young-info-datepicker"
              selected={birthday}
              onChange={e => setBirthDay(e)}
            />
          </Box>
          <Text
            color="title-gray"
            lineHeight="normal"
            fontSize="22px"
            margin={0}
          >
            {getYears(new Date(age).toISOString())} years old
          </Text>
        </Box>
      </Box>
    </Box>
  </ProfileSection>
)

export default YoungInfoEditCard
