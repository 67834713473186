import React from 'react'
import 'styled-components/macro'

import { Box, Text } from 'design-system'

const Avatar = ({
  src,
  name,
  size,
  bg,
  fontSize,
  backgroundImage,
  ...otherProps
}) => {
  if (!src) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={size}
        height={size}
        bg="near-black"
        overflow="hidden"
        borderRadius="50%"
        backgroundImage={backgroundImage}
        {...otherProps}
      >
        <Text color="white" fontSize={fontSize} lineHeight="0">
          {name.charAt(0).toUpperCase()}
          {name
            .split(' ')[1]
            .charAt(0)
            .toUpperCase()}
        </Text>
      </Box>
    )
  }

  return (
    <Box
      width={size}
      height={size}
      bg={bg}
      borderRadius="50%"
      overflow="hidden"
      {...otherProps}
      css={{
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  )
}

Avatar.defaultProps = {
  fontSize: 1,
  backgroundImage: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%);',
  bg: 'near-black',
}

export default Avatar
