import { format, differenceInYears } from 'date-fns'

export const formatDate = date => {
  const [year, month, day] = date.substr(0, 10).split('-')
  const [hour, minute] = date.substr(11, 13).split(':')

  return format(
    new Date(year, month - 1, day, hour, minute),
    'Do MMMM, h:mm aa'
  )
}

export const formatDateBirthday = date => {
  const [year, month, day] = date.substr(0, 10).split('-')
  return format(new Date(year, month - 1, day), 'M/D/YYYY')
}

export const getYears = date => {
  const [year, month, day] = date.substr(0, 10).split('-')
  return differenceInYears(new Date(), new Date(year, month - 1, day))
}

export const getAge = birthday =>
  Date.UTC(
    new Date(birthday).getUTCFullYear(),
    new Date(birthday).getUTCMonth(),
    new Date(birthday).getUTCDate(),
    new Date(birthday).getUTCHours(),
    new Date(birthday).getUTCMinutes(),
    new Date(birthday).getUTCSeconds()
  )
