export const statuses = {
  COMMUTING: 'Commuting',
  IN_MEETING: 'In Meeting',
  BUSY: 'Busy',
  AVAILABLE: 'Available',
  VACATION: 'Vaction',
}

export const youngStatuses = {
  UNEMPLOYED: 'Unemployed',
  WORKING: 'Working',
  IN_UNIVERSITY: 'In University',
  IN_SCHOOL: 'In School',
  IN_COLLEGE: 'In College',
}

export const youngAddressTypes = {
  FOSTER_CARERS: 'Foster Carers',
  PERSONAL: 'Personal',
  ACCOMMODATION: 'Accommodation',
}

export const youngInterests = {
  ACTING: 'Acting',
  ARCHITECTURE: 'Architecture',
  CARS: 'Cars',
  CODING: 'Coding',
  DANCE: 'Dance',
  FASHION: 'Fashion',
  FILM: 'Film',
  FOOD: 'Food',
  FOOTBALL: 'Football',
  GAMING: 'Gaming',
  GYM: 'Gym',
  HORSE_RIDING: 'Horse Riding',
  MUSIC: 'Music',
  PHOTOGRAPHY: 'Photography',
  PIANO: 'Piano',
  READING: 'Reading',
  ROCK_CLIMBING: 'Rock Climbing',
  RUGBY: 'Rugby',
  RUNNING: 'Running',
  SCIENCE: 'Science',
  SINGING: 'Singing',
  SNOOKER: 'Snooker',
  SOCIAL_CARE: 'Social Care',
  STARTUPS: 'Startups',
  SURFING: 'Surfing',
  SWIMMING: 'Swimming',
  TECH: 'Tech',
  VIDEO_GAMES: 'Video Games',
  WOODWORK: 'Woodwork',
  WRITING: 'Writing',
  YOGA: 'Yoga',
}

export const teams = {
  MUSE: 'Muse',
  FUJI: 'Fuji',
  K2: 'K2',
}

export const roles = {
  ADMIN: 'Admin',
  MANAGER: 'MANAGER',
  SOCIAL_WORKER: 'Social Worker',
  PA: 'PA',
}

export const postCategories = {
  EDUCATION: 'Education',
  HOUSING: 'Housing',
  MONEY: 'Money',
  HEALTH: 'Health',
  JOBS: 'Jobs',
  FAMILY: 'Family',
  ISSUES: 'Issues',
  OTHER: 'Other',
}
