import React, { useState } from 'react'

import { Box } from 'design-system'
import { TextareaInput } from 'components/edit-cards/style'

const EditableCopy = ({ initialText, onTextareaChange }) => {
  const [textarea, setTextarea] = useState(initialText)
  const onChange = e => {
    const { value } = e.target
    setTextarea(value)
    onTextareaChange(value)
  }

  return (
    <Box display="flex" width="100%" flexDirection="column" my="10px">
      <TextareaInput
        autoFocus
        rows="4"
        cols="50"
        resize="none"
        name="setTextarea"
        placeholder=""
        value={textarea}
        onChange={onChange}
      />
    </Box>
  )
}

export default EditableCopy
