import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../../design-system'

export const activeStyle = {
  opacity: 1,
  backgroundColor: theme.colors['sidebar-hover'],
}

export const StyledNavLink = styled(NavLink)({
  justifyContent: 'flex-start',
  alignItems: 'center',
  display: 'flex',
  height: '65px',
  width: '100%',
})
