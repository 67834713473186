import React from 'react'

import ChatMessages from './messages'
import { Box } from 'design-system'

const ChatMessage = ({ young, user }) => {
  return (
    <Box display="flex" flexDirection="column" flex={3} minWidth="0" as="span">
      <ChatMessages young={young} user={user} />
    </Box>
  )
}

export default ChatMessage
