import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const changePasswordQuery = gql`
  mutation changePassword($password: String!) {
    changePassword(password: $password) {
      token
    }
  }
`

const changePasswordOptions = {
  props: ({ mutate }) => ({
    changePassword: async ({ password }) => {
      return mutate({
        variables: {
          password,
        },
      })
    },
  }),
}

export default graphql(changePasswordQuery, changePasswordOptions)
