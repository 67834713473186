import React from 'react'
import styled from 'styled-components'

const TagAddButton = ({ title, action, disabled, ...otherProps }) => (
  <Button mx={2} onClick={action} disabled={disabled}>
    {title}
  </Button>
)

export default TagAddButton

const Button = styled.button(({ disabled, theme }) => ({
  backgroundColor: '#D3D3D3',
  fontWeight: theme.fontWeights[1],
  fontSize: theme.fontSizes[1],
  fontFamily: theme.fonts.sans,
  color: '#A6A7A8',
  padding: '3px 32px',
  outline: 'none',
  borderRadius: 3,
  height: '50px',
  minWidth: '50px',
  border: 0,
  margin: 5,
  transition: 'opacity 0.3s ease',
  opacity: disabled ? 0.5 : 1,
}))
