import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export const getAuthQuery = gql`
  query authStatus {
    authStatus @client {
      logged
      user
      role
    }
  }
`

export const getAuthOptions = {
  props: ({ data: { error, loading, authStatus }, client }) => ({
    data: {
      error,
      loading,
      authStatus,
      logged: authStatus && authStatus.logged,
    },
    client,
  }),
  options: {
    fetchPolicy: 'cache-only',
  },
}

export default graphql(getAuthQuery, getAuthOptions)
