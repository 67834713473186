import {
  space,
  color,
  layout,
  flexbox,
  border,
  background,
  position,
  typography,
} from 'styled-system'

import styled from 'styled-components'
import { theme } from './theme'

export const Box = styled('div')(
  {},
  space,
  color,
  layout,
  flexbox,
  border,
  background,
  position
)

export const Text = styled('p')({}, space, color, typography)

Text.defaultProps = {
  fontSize: 2,
  color: 'black',
  fontFamily: 'sans',
}

export const Heading = styled(Text)({})
Heading.defaultProps = {
  as: 'h1',
  m: 0,
  fontSize: 4,
  color: 'black',
  fontWeight: 2,
  fontFamily: 'title',
  lineHeight: 'title',
}

export const Button = styled(Text)({
  appearance: 'none',
  border: 'none',
  borderRadius: '4px',
})

Button.defaultProps = {
  display: 'block',
  m: 0,
  py: 3,
  px: 5,
  as: 'button',
  fontWeight: 1,
  fontSize: 1,
  fontFamily: 'sans',
  color: 'white',
  bg: theme.colors.button,
}
