import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const updateYoungBasicInfoQuery = gql`
  mutation UpdateYoungBasicInfo(
    $young: ID!
    $dob: DateTime!
    $status: YoungStatus
    $address: String
  ) {
    updateYoungBasicInfo(
      young: $young
      dob: $dob
      status: $status
      address: $address
    ) {
      dob
      status
      address
    }
  }
`

const updateYoungBasicInfoOptions = {
  props: ({ mutate }) => ({
    updateYoungBasicInfo: async ({ young, dob, status, address }) => {
      return mutate({
        variables: {
          young,
          dob,
          status,
          address,
        },
      })
    },
  }),
}

export default graphql(updateYoungBasicInfoQuery, updateYoungBasicInfoOptions)
