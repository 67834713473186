import React, { Component } from 'react'
import { compose } from 'recompose'

import { Box, theme, Text } from 'design-system'
import { LoginIcon, LoginButtonIcon } from 'components/icons'
import { Input, ButtonLogin } from './style'

import login from 'graphql/mutations/user/login'

class Login extends Component {
  state = {
    email: '',
    password: '',
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.handleSubmit, false)
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleSubmit, false)
  }

  handleSubmit = e => {
    if (e.keyCode === 13) this.submit()
  }

  submit = async () => {
    const { login } = this.props
    const { email, password } = this.state
    this.setState({ loading: true })
    await login({ email, password })
      .then(res => {
        const { data } = res
        const { login } = data
        const { token } = login
        this.setState({ loading: false })
        localStorage.setItem('token', token)
        this.props.history.push('/young/:id')
      })
      .catch(error => {
        console.log('error ', error)
        this.setState({ loading: false, error: true })
      })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const { loading, error } = this.state
    return (
      <div>
        <Box
          width="100vw"
          minHeight="100vh"
          display="flex"
          height="100vh"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box my={4}>
            <LoginIcon fill={theme.colors.black} />
          </Box>
          <Input
            type="text"
            name="email"
            placeholder="Email address"
            autoComplete="off"
            onChange={this.handleChange}
          />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            autoComplete="off"
            onChange={this.handleChange}
          />
          <Box
            width="300px"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            height="20px"
            px="10px"
          >
            {error && (
              <Text fontSize={0} color="red">
                User not found
              </Text>
            )}
          </Box>

          <ButtonLogin onClick={this.submit}>
            {loading ? (
              <div className="dot" />
            ) : (
              <Box
                width="70px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                Log in
                <LoginButtonIcon
                  stroke={theme.colors.white}
                  width={19}
                  height={19}
                />
              </Box>
            )}
          </ButtonLogin>
        </Box>
      </div>
    )
  }
}

export default compose(login)(Login)
