import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const sendMessageMutation = gql`
  mutation SendMessageMutation(
    $sender: ID!
    $recipient: ID!
    $message: String!
    $file: Upload
  ) {
    sendMessage(
      sender: $sender
      recipient: $recipient
      message: $message
      file: $file
    ) {
      id
      message
      file {
        id
        filename
        mimetype
        url
      }
    }
  }
`

const sendMessageOptions = {
  props: ({ mutate }) => ({
    sendMessage: ({ sender, recipient, message, file }) => {
      return mutate({
        variables: {
          sender,
          recipient,
          message,
          file,
        },
      })
    },
  }),
}

export default graphql(sendMessageMutation, sendMessageOptions)
