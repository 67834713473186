import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export const getLastMessageQuery = gql`
  query LastMessageQuery($young: ID!, $user: ID!) {
    lastMessage(id: $young, user: $user) {
      message
    }
  }
`

export const getLastMessageOptions = {
  props: ({ data: { error, loading, networkStatus, lastMessage } }) => ({
    data: {
      error,
      loading,
      networkStatus,
      lastMessage,
      hasMessage: lastMessage && lastMessage.length > 0,
      message:
        lastMessage && lastMessage.length > 0 ? lastMessage[0].message : '',
    },
  }),
  options: ({ young, user }) => ({
    variables: { young, user },
    pollInterval: 500,
    fetchPolicy: 'network-only',
  }),
}

export default graphql(getLastMessageQuery, getLastMessageOptions)
