import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { theme } from 'design-system'

const DarkButton = withStyles({
  root: {
    textTransform: 'capitalize',
    marginLeft: 15,
    width: 150,
    boxShadow: 'none',
    border: 0,
    backgroundColor: theme.colors.black,
    fontFamily: theme.fonts.sans,
    '&:hover': {
      backgroundColor: theme.colors.black,
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.colors.black,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button)

export default function CustomizedButtons({ text, ...otherProps }) {
  return (
    <DarkButton
      variant="contained"
      color="primary"
      disableRipple
      {...otherProps}
    >
      {text}
    </DarkButton>
  )
}
