import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import { getYoungPostsQuery } from 'graphql/queries/young/getYoungPosts'

const deletePostMutation = gql`
  mutation deletePostMutation($post: ID!) {
    deletePost(post: $post) {
      id
      text
      updatedAt
    }
  }
`

const deletePostMutationOptions = {
  props: ({ mutate }) => ({
    deletePost: async ({ post, young }) => {
      return mutate({
        variables: {
          post,
        },

        refetchQueries: [
          {
            query: getYoungPostsQuery,
            variables: { young, category: '' },
          },
        ],
      })
    },
  }),
}

export default graphql(deletePostMutation, deletePostMutationOptions)
