import React from 'react'
import { Box } from 'design-system'

import {
  PostPlaceholder,
  RoundedPlaceholder,
  SquarePlaceholder,
  HeaderPlaceholder,
} from 'components/content-placeholders'

export const callPostsPlaceholder = () =>
  ['', ''].map((i, index) => (
    <PostPlaceholder
      key={`posts-placeholder-${index}`}
      width={60}
      height={60}
      margin={10}
    />
  ))

export const callSidebarPlaceholder = () =>
  ['', '', ''].map((i, index) => (
    <Box
      key={`sidebar-placeholder-${index}`}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      height="65px"
      width="100%"
      px="15px"
    >
      <RoundedPlaceholder
        ready={false}
        width={40}
        height={40}
        margin="0 15px 0 0"
      />
      <SquarePlaceholder ready={false} width="65%" height={20} />
    </Box>
  ))

export const callSearchPlaceholder = () => (
  <SquarePlaceholder ready={false} width="100%" height={35} />
)

export const callSmTitlePlaceholder = () => (
  <SquarePlaceholder ready={false} width={120} height={20} />
)

export const callHeaderPlaceholder = () => <HeaderPlaceholder />
