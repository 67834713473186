import React, { useState } from 'react'
import { Box, Text } from 'design-system'

import YoungInterestEditCard from 'components/edit-cards/young-interests'
import ProfileSection from 'components/sections/profile'
import { youngInterests } from 'enums'
import Tag from 'components/tag'

const InterestCard = ({ interests, updateByPicture, submitInterest }) => {
  const [newInterests, setNewInterests] = useState(interests)
  const [isEditOpen, setEdit] = useState(false)

  const handleAdd = value => {
    setNewInterests([...newInterests, value])
  }

  const handleRemove = value => {
    const newArray = newInterests.filter(item => item !== value)
    setNewInterests(newArray)
  }

  const submitChanges = () => {
    submitInterest(newInterests)
    setEdit(false)
  }

  return !isEditOpen ? (
    <ProfileSection
      after={`Last Updated on 21/03/2019 13:11 by`}
      updateByName={<span>Loren Spears</span>}
      updateByPicture={updateByPicture}
      right={<span>•••</span>}
      headerTitle="Interests"
      editToggle={() => setEdit(true)}
      mx="auto"
      mt={3}
    >
      <Box display="flex" flexWrap="wrap" alignItems="center">
        {newInterests.map(item => (
          <Tag bgColor="interest" key={`INTEREST_${item}`}>
            <Text
              lineHeight="normal"
              fontSize={1}
              margin={0}
              color="interest-text"
            >
              {youngInterests[item]}
            </Text>
          </Tag>
        ))}
      </Box>
    </ProfileSection>
  ) : (
    <YoungInterestEditCard
      submitChanges={submitChanges}
      interests={newInterests}
      onRemove={handleRemove}
      isEditOpen={isEditOpen}
      setEdit={setEdit}
      onAdd={handleAdd}
    />
  )
}

export default InterestCard
