import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import {
  getCasesQuery,
  getOtherCasesQuery,
} from 'graphql/queries/cases/getCases'

const removeCaseQuery = gql`
  mutation removeCase($young: ID!) {
    removeCase(young: $young) {
      id
    }
  }
`

const removeCaseOptions = {
  props: ({ mutate }) => ({
    removeCase: async ({ young }) => {
      return mutate({
        variables: {
          young,
        },
        // Update cache
        refetchQueries: [
          { query: getCasesQuery },
          { query: getOtherCasesQuery },
        ],
      })
    },
  }),
}

export default graphql(removeCaseQuery, removeCaseOptions)
