import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const updatePostQuery = gql`
  mutation updatePost($post: ID!, $text: String!) {
    updatePost(post: $post, text: $text) {
      id
      text
      updatedAt
    }
  }
`

const updatePostOptions = {
  props: ({ mutate }) => ({
    updatePost: async ({ post, text }) => {
      return mutate({
        variables: {
          post,
          text,
        },
      })
    },
  }),
}

export default graphql(updatePostQuery, updatePostOptions)
