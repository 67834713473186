import React from 'react'
import { compose } from 'react-apollo'

import AuthRoute from 'auth-route'
import { Page, Nav, SideBar } from 'components'
import { Box } from 'design-system'

import Section from './section'

const YoungProfile = ({ match }) => {
  const { id } = match.params
  return (
    <Page>
      <Nav />
      <SideBar />
      <Box flex={1} p={3} overflow="auto" height="100vh">
        <Section young={id} />
      </Box>
    </Page>
  )
}

export default compose(AuthRoute)(YoungProfile)
