import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const updateYoungNotesQuery = gql`
  mutation UpdateYoungNotes($young: ID!, $notes: String!) {
    updateYoungNotes(young: $young, notes: $notes) {
      notes
    }
  }
`

const updateYoungNotesOptions = {
  props: ({ mutate }) => ({
    updateYoungNotes: async ({ young, notes }) => {
      return mutate({
        variables: {
          young,
          notes,
        },
      })
    },
  }),
}

export default graphql(updateYoungNotesQuery, updateYoungNotesOptions)
