import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import { subscribeToNewMessages } from 'graphql/subscriptions'

export const getYoungMessagesQuery = gql`
  query YoungMessagesQuery($young: ID!, $user: ID!) {
    youngMessages(id: $young, user: $user) {
      id
      message
      sender
      recipient
      file {
        id
        filename
        mimetype
        url
      }
    }
  }
`

export const getYoungMessagesOptions = {
  props: ({
    data: {
      error,
      loading,
      networkStatus,
      subscribeToMore,
      refetch,
      youngMessages,
    },
  }) => ({
    data: {
      error,
      loading,
      networkStatus,
      refetch,
      youngMessages,
      messages: youngMessages || [],
      subscribeToNewMessages: () => {
        return subscribeToMore({
          document: subscribeToNewMessages,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            const { chat } = subscriptionData.data
            const newChat = {
              ...chat,
            }
            if (!chat.file) newChat.file = null
            const youngMessages = [...prev.youngMessages, newChat]
            return {
              youngMessages,
            }
          },
        })
      },
    },
  }),
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
}

export default graphql(getYoungMessagesQuery, getYoungMessagesOptions)
