import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import { getCurrentUserQuery } from 'graphql/queries/user/getCurrentUser'

const updateAvatarQuery = gql`
  mutation updateAvatar($avatar: Upload!) {
    updateAvatar(avatar: $avatar) {
      id
      name
      avatarUrl
      avatar {
        url
      }
      status
      role
      email
    }
  }
`

const updateAvatarOptions = {
  props: ({ mutate }) => ({
    updateAvatar: async ({ avatar }) => {
      return mutate({
        variables: {
          avatar,
        },

        refetchQueries: [
          {
            query: getCurrentUserQuery,
          },
        ],
      })
    },
  }),
}

export default graphql(updateAvatarQuery, updateAvatarOptions)
