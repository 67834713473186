import React from 'react'
import { compose } from 'recompose'

import getLastMessage from 'graphql/queries/chat/getLastMessage'

import { Box, Text } from 'design-system'

function ChatLastMessage({ selected, data }) {
  const { hasMessage } = data
  let lastMessage

  if (hasMessage) {
    lastMessage = data.message
    if (lastMessage.length > 18) {
      lastMessage = `${lastMessage.slice(0, 18)}..`
    }
  }

  return (
    <Box minHeight={17}>
      {hasMessage ? (
        <Text m={0} color={selected ? 'near-white' : 'near-black'} fontSize={1}>
          {lastMessage}
        </Text>
      ) : (
        <Text m={0} color={selected ? 'near-white' : 'near-black'} fontSize={1}>
          Send a message
        </Text>
      )}
    </Box>
  )
}

export default compose(getLastMessage)(ChatLastMessage)
