import React, { Fragment } from 'react'
import { Box, Text } from 'design-system'
import styled from 'styled-components'
import { compose } from 'recompose'

import { callPostsPlaceholder } from 'utils/placeholder-factory'

import getYoungPosts from 'graphql/queries/young/getYoungPosts'

import TimelineCard from 'components/cards/timeline'
import EmptyPosts from './empty-posts'

import { roles, postCategories } from 'enums'

const TimelinePosts = ({ data, category, onClearButtonClick, young }) => {
  const { hasPosts, youngPosts, loading, networkStatus } = data

  if ((loading && networkStatus === 1) || young === ':id')
    return callPostsPlaceholder()
  if (!hasPosts && loading === false) return <EmptyPosts category={category} />

  const categoryFilter =
    category && category.length > 0 ? (
      <Box width="100%" maxWidth={6} mx="auto" px={3}>
        <Box display="flex" alignItems="center">
          <Text m={0} fontSize={2} color="medium-gray">
            Filtering logs by{' '}
            <Text m={0} as="span" color="black">
              {postCategories[category]}
            </Text>
          </Text>
          <ClearButton onClick={onClearButtonClick}>Clear</ClearButton>
        </Box>
      </Box>
    ) : null

  const cards = youngPosts.map(item => (
    <TimelineCard
      postId={item.id}
      young={young}
      key={`card-by-${item.createdBy.name}-${item.createdAt}`}
      avatarUrl={item.createdBy.avatarUrl}
      avatar={item.createdBy.avatar}
      senderName={item.createdBy.name}
      senderRole={roles[item.createdBy.role]}
      text={item.text}
      headerTitle={item.createdAt}
      tagColor={`${item.category.toLowerCase()}`}
      tagTextColor={`${item.category.toLowerCase()}-text`}
      tagName={item.category}
      file={item.file}
    />
  ))

  return (
    <Fragment>
      {categoryFilter}
      {cards}
    </Fragment>
  )
}

export default compose(getYoungPosts)(TimelinePosts)

const ClearButton = styled.button(({ theme }) => ({
  marginLeft: theme.space[2],
  paddingLeft: theme.space[2],
  paddingRight: theme.space[2],
  appearance: 'none',
  outline: 'none',
  background: 'none',
  border: `solid 1px ${theme.colors.gray}`,
  fontFamily: 'inherit',
  fontSize: theme.fontSizes[1],
  borderRadius: '2px',
}))
