import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const updateYoungInterestsQuery = gql`
  mutation UpdateYoungInterests($young: ID!, $interests: [YoungInterest!]!) {
    updateYoungInterests(young: $young, interests: $interests) {
      interests
    }
  }
`

const updateYoungInterestsOptions = {
  props: ({ mutate }) => ({
    updateYoungInterests: async ({ young, interests }) => {
      return mutate({
        variables: {
          young,
          interests,
        },
      })
    },
  }),
}

export default graphql(updateYoungInterestsQuery, updateYoungInterestsOptions)
