import React from 'react'
import { compose } from 'recompose'

import { getCases } from 'graphql/queries/cases/getCases'

import ChatItem from './item'

function ConversationList({ data, user, selected, handleItemClick }) {
  const { allCases } = data
  return allCases.map(item => {
    const { young } = item
    const youngId = young.id
    const avatarSrc = young.avatar ? young.avatar.url : young.avatarUrl
    return (
      <ChatItem
        key={`chat-item-${youngId}`}
        chatId={`chat-${youngId}`}
        name={young.name}
        young={youngId}
        user={user}
        avatarSrc={avatarSrc}
        selected={selected === youngId}
        onItemClick={handleItemClick}
      />
    )
  })
}

export default compose(getCases)(ConversationList)
