import React, { Fragment, useState } from 'react'
import { compose } from 'recompose'

import InterestCard from 'components/cards/interests'
import DreamCard from 'components/cards/dreams'
import Header from 'components/young/header'
import InfoCard from 'components/cards/info'

import updateYoungInterests from 'graphql/mutations/young/updateYoungInterests'
import updateYoungBasicInfo from 'graphql/mutations/young/updateYoungBasicInfo'
import assignCaseToMe from 'graphql/mutations/cases/assignCaseToMe'
import isCaseAssignedToMe from 'graphql/queries/cases/isCaseAssignedToMe'
import removeCase from 'graphql/mutations/cases/removeCase'
import updateYoungNotes from 'graphql/mutations/young/updateYoungNotes'
import getYoung from 'graphql/queries/young/getYoung'

import { callPostsPlaceholder } from 'utils/placeholder-factory'
import { youngStatuses } from 'enums'

const YoungHeader = compose(
  isCaseAssignedToMe,
  assignCaseToMe,
  removeCase
)(Header)

const YoungSection = ({
  updateYoungInterests,
  updateYoungBasicInfo,
  updateYoungNotes,
  young,
  data,
}) => {
  const [interests, setInterests] = useState([])
  const [address, setAddress] = useState('')
  const [status, setStatus] = useState('')
  const [notes, setNotes] = useState('')
  const [dob, setDob] = useState('')

  const submitInfo = async (dob, address, status) => {
    if (
      data.young.dob !== dob ||
      data.young.address !== address ||
      data.young.status !== status
    ) {
      const response = await updateYoungBasicInfo({
        young,
        dob,
        status,
        address,
      })
      setStatus(youngStatuses[response.data.updateYoungBasicInfo.status])
      setAddress(response.data.updateYoungBasicInfo.address)
      setDob(response.data.updateYoungBasicInfo.dob)
    }
  }

  const submitDream = async notes => {
    if (data.young.notes !== notes) {
      const response = await updateYoungNotes({ young, notes })
      setNotes(response.data.updateYoungNotes.notes)
    }
  }

  const submitInterest = async interests => {
    if (JSON.stringify(data.young.interests) !== JSON.stringify(interests)) {
      const response = await updateYoungInterests({ young, interests })
      setInterests(response.data.updateYoungInterests.interests)
    }
  }

  useState(() => {
    if (data && data.young) {
      setStatus(youngStatuses[data.young.status])
      setInterests(data.young.interests)
      setAddress(data.young.address)
      setNotes(data.young.notes)
      setDob(data.young.dob)
    }
  }, [data])

  return (
    <Fragment>
      {data && data.young && (
        <Fragment>
          <YoungHeader
            avatarSrc={
              data.young.avatar ? data.young.avatar.url : data.young.avatarUrl
            }
            name={data.young.name}
            young={young}
          />

          {data.loading ? (
            callPostsPlaceholder()
          ) : (
            <Fragment>
              <InfoCard
                submitInfo={submitInfo}
                address={address}
                status={status}
                birthdate={dob}
                years="22"
              />

              <DreamCard dreamText={notes} submitDream={submitDream} />

              <InterestCard
                submitInterest={submitInterest}
                interests={interests}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default compose(
  getYoung,
  updateYoungBasicInfo,
  updateYoungNotes,
  updateYoungInterests
)(YoungSection)
