import React from 'react'
import { Box } from 'design-system'

const Page = ({ children, ...otherProps }) => (
  <Box width="100vw" minHeight="100vh" display="flex" {...otherProps}>
    {children}
  </Box>
)

Page.defaultProps = {
  bg: 'background',
}

export default Page
